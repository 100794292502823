<template>
    <div class="md:py-1 rtb-header">
        <div class="business-logo invisible w-0 h-0">
            <img src="/images/aside-square-black.png" alt="logo"/>
        </div>
        <!-- Off-canvas menu for mobile -->
        <div v-if="user && sidebarOpen" class="md:hidden">
            <div
                @click="sidebarOpen = false"
                v-show="sidebarOpen"
                class="fixed inset-0 z-30 transition-opacity ease-linear duration-500"
            >
                <!--<div class="absolute inset-0 bg-gray-600 opacity-75"></div>-->
            </div>
            <div class="fixed inset-0 flex z-40">
                <div
                    v-if="sidebarOpen && user"
                    class="flex flex-col max-w-xs w-full bg-white transform ease-in-out duration-500"
                    v-click-away="onClickAway"
                >
                    <div class="grid gap-10 p-6 overflow-y-auto mb-32">
                        <div class="close-and-lang flex justify-between items-center">
                            <div class="close cursor-pointer" @click="toggleSidebar(false)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8824 14.0034L17.1854 19.3074C17.4668 19.5888 17.8485 19.7469 18.2464 19.7469C18.6444 19.7469 19.026 19.5888 19.3074 19.3074C19.5888 19.026 19.7469 18.6444 19.7469 18.2464C19.7469 17.8485 19.5888 17.4668 19.3074 17.1854L14.0024 11.8824L19.3064 6.57943C19.4457 6.4401 19.5561 6.2747 19.6315 6.09268C19.7068 5.91066 19.7456 5.71558 19.7455 5.51858C19.7455 5.32158 19.7066 5.12652 19.6312 4.94453C19.5558 4.76255 19.4452 4.5972 19.3059 4.45793C19.1666 4.31867 19.0012 4.20821 18.8192 4.13286C18.6371 4.05752 18.4421 4.01876 18.2451 4.01881C18.0481 4.01885 17.853 4.0577 17.671 4.13313C17.489 4.20856 17.3237 4.3191 17.1844 4.45843L11.8824 9.76143L6.57942 4.45843C6.44111 4.31511 6.27565 4.20076 6.09268 4.12206C5.90971 4.04336 5.7129 4.00189 5.51374 4.00006C5.31457 3.99824 5.11703 4.0361 4.93265 4.11143C4.74827 4.18677 4.58074 4.29807 4.43984 4.43884C4.29894 4.57961 4.18748 4.74704 4.11197 4.93134C4.03646 5.11565 3.99841 5.31315 4.00005 5.51232C4.00169 5.71149 4.04297 5.90834 4.1215 6.09138C4.20002 6.27443 4.31422 6.44 4.45742 6.57843L9.76242 11.8824L4.45842 17.1854C4.17702 17.4668 4.01894 17.8485 4.01894 18.2464C4.01894 18.6444 4.17702 19.026 4.45842 19.3074C4.73981 19.5888 5.12146 19.7469 5.51942 19.7469C5.91737 19.7469 6.29902 19.5888 6.58042 19.3074L11.8824 14.0024V14.0034Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="mid-line"></div>
                            <div class="language-switch">
                                <SwitchLang/>
                            </div>
                        </div>

                        <div class="user-profile cursor-pointer" @click="$router.push('/customer/profile')">
                            <div class="user-icon-name flex items-center gap-3">
                                <div class="image border-0 w-14 border-r-2 border-gray-300">
                                    <img class="w-10 h-10 rounded-full flex items-start justify-center"
                                         src="/images/user-icon.png" alt="user">
                                </div>
                                <div class="user-name-email grid">
                                    <div class="name font-bold text-base">
                                        <p>{{ user.name }}</p>
                                    </div>
                                    <div class="email">
                                        <p>{{ user.email ?? 'example@test.com' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="menu-item my-profile flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/provider/profile')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7C16 8.06087 15.5786 9.07828 14.8284 9.82843C14.0783 10.5786 13.0609 11 12 11C10.9391 11 9.92172 10.5786 9.17157 9.82843C8.42143 9.07828 8 8.06087 8 7ZM8 13C6.67392 13 5.40215 13.5268 4.46447 14.4645C3.52678 15.4021 3 16.6739 3 18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 16.6739 20.4732 15.4021 19.5355 14.4645C18.5979 13.5268 17.3261 13 16 13H8Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>প্রোফাইল</p>
                            </div>
                        </div>

                        <div class="menu-item my-profile flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/provider/car/index')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_1_6127)">
                                        <path d="M22.5 9.15L20.4 4.8C19.8 3.75 18.75 3 17.55 3H6.45C5.25 3 4.2 3.75 3.6 4.8L1.5 9.15C0.6 9.3 0 10.05 0 10.8V16.05C0 16.95 0.6 17.7 1.5 17.85V20.85C1.5 21.75 2.25 22.5 3.15 22.5H4.5C5.25 22.5 6 21.75 6 20.85V18H18V20.85C18 21.75 18.75 22.5 19.65 22.5H21C21.9 22.5 22.65 21.75 22.65 20.85V17.85C23.55 17.7 24.15 16.95 24.15 16.05V10.8C24 10.05 23.4 9.3 22.5 9.15ZM6 12.6C6 13.05 5.55 13.5 5.1 13.5H2.4C1.95 13.5 1.5 13.05 1.5 12.6V11.4C1.5 10.95 1.95 10.5 2.4 10.5H5.1C5.55 10.5 6 10.95 6 11.4V12.6ZM15 16.5H9V15H15V16.5ZM3.15 9L4.95 5.4C5.25 4.8 5.85 4.5 6.45 4.5H17.55C18.15 4.5 18.75 4.8 19.05 5.4L20.85 9H3.15ZM22.5 12.6C22.5 13.05 22.05 13.5 21.6 13.5H18.9C18.45 13.5 18 13.05 18 12.6V11.4C18 10.95 18.45 10.5 18.9 10.5H21.6C22.05 10.5 22.5 10.95 22.5 11.4V12.6Z" fill="black"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_6127">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>কার</p>
                            </div>
                        </div>

                        <div class="menu-item my-profile flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/my-wallet')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M14 13C13.1667 13 12.4583 12.7083 11.875 12.125C11.2917 11.5417 11 10.8333 11 10C11 9.16667 11.2917 8.45833 11.875 7.875C12.4583 7.29167 13.1667 7 14 7C14.8333 7 15.5417 7.29167 16.125 7.875C16.7083 8.45833 17 9.16667 17 10C17 10.8333 16.7083 11.5417 16.125 12.125C15.5417 12.7083 14.8333 13 14 13ZM5 16V4H23V16H5ZM9 14H19C19 13.45 19.196 12.979 19.588 12.587C19.98 12.195 20.4507 11.9993 21 12V8C20.45 8 19.979 7.804 19.587 7.412C19.195 7.02 18.9993 6.54933 19 6H9C9 6.55 8.804 7.021 8.412 7.413C8.02 7.805 7.54933 8.00067 7 8V12C7.55 12 8.021 12.196 8.413 12.588C8.805 12.98 9.00067 13.4507 9 14ZM1 20V7H3V18H20V20H1Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>পেমেন্ট</p>
                            </div>
                        </div>

                        <div class="menu-item my-profile flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/referral')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M14 13C13.1667 13 12.4583 12.7083 11.875 12.125C11.2917 11.5417 11 10.8333 11 10C11 9.16667 11.2917 8.45833 11.875 7.875C12.4583 7.29167 13.1667 7 14 7C14.8333 7 15.5417 7.29167 16.125 7.875C16.7083 8.45833 17 9.16667 17 10C17 10.8333 16.7083 11.5417 16.125 12.125C15.5417 12.7083 14.8333 13 14 13ZM5 16V4H23V16H5ZM9 14H19C19 13.45 19.196 12.979 19.588 12.587C19.98 12.195 20.4507 11.9993 21 12V8C20.45 8 19.979 7.804 19.587 7.412C19.195 7.02 18.9993 6.54933 19 6H9C9 6.55 8.804 7.021 8.412 7.413C8.02 7.805 7.54933 8.00067 7 8V12C7.55 12 8.021 12.196 8.413 12.588C8.805 12.98 9.00067 13.4507 9 14ZM1 20V7H3V18H20V20H1Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>রেফার করুন</p>
                            </div>
                        </div>

                        <div class="menu-item contact flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/contact')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M21 8V7L18 9L15 7V8L18 10L21 8ZM22 3H2C0.9 3 0 3.9 0 5V19C0 20.1 0.9 21 2 21H22C23.1 21 23.99 20.1 23.99 19L24 5C24 3.9 23.1 3 22 3ZM8 6C9.66 6 11 7.34 11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6ZM14 18H2V17C2 15 6 13.9 8 13.9C10 13.9 14 15 14 17V18ZM22 12H14V6H22V12Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>যোগাযোগ</p>
                            </div>
                        </div>

                        <div class="menu-item help-line flex gap-5 items-center ml-14">
                            <div class="icon">
                                <a href="https://wa.me/1871955556">
                                    <svg fill="#00000" height="24px" width="24px" version="1.1" id="Layer_1"
                                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                         viewBox="0 0 324 324" xml:space="preserve">
                                          <g id="XMLID_468_">
                                            <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                                              c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                                              c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                                              c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                                              c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                                              c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                                              c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                                              c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                                              c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                                              C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                              <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                                              c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                                              c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                                              M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                                              l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                                              c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                                              C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                          </g>
                                    </svg>
                                </a>
                            </div>
                            <div class="title text-sm font-semibold">
                                <a href="https://wa.me/1871955556">হোয়াটসঅ্যাপ</a>
                            </div>
                        </div>

                        <div class="menu-item help-line flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/help')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z" fill="black"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>হেল্প</p>
                            </div>
                        </div>

                        <div
                            class="logout-rtb h-10 w-4/5  px-5 flex gap-4 items-center justify-center absolute left-0 right-0 bottom-5 mx-auto cursor-pointer"
                            v-if="user"
                            @click="logOut()"
                        >
                            <span class="text-white text-sm font-semibold">লগ আউট</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19"
                                 fill="none">
                                <path
                                    d="M18.4412 9.49989H8.38235M16.3235 12.6764L19.5 9.49989L16.3235 6.32341M11.0294 4.20577V3.14694C11.0294 2.58531 10.8063 2.04668 10.4092 1.64954C10.012 1.25241 9.4734 1.0293 8.91177 1.0293H3.61765C3.05601 1.0293 2.51738 1.25241 2.12024 1.64954C1.72311 2.04668 1.5 2.58531 1.5 3.14694V15.8528C1.5 16.4145 1.72311 16.9531 2.12024 17.3502C2.51738 17.7474 3.05601 17.9705 3.61765 17.9705H8.91177C9.4734 17.9705 10.012 17.7474 10.4092 17.3502C10.8063 16.9531 11.0294 16.4145 11.0294 15.8528V14.794"
                                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
<!--                    <div class="absolute top-0 right-0 -mr-14 p-1">
                        <button
                            v-show="sidebarOpen"
                            @click="toggleSidebar(false)"
                            class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                        >
                            <svg
                                class="h-6 w-6 text-black"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto relative">
                        <div class="flex-shrink-0 flex items-center px-4">
                            <router-link to="/">
                                <img
                                    class="rtb-logo inline w-48"
                                    src="/images/aside-square-black.png"
                                    alt="rtb-logo"
                                />
                            </router-link>
                        </div>
                        <div class="user-lang flex justify-between p-4 mt-4">
                            <div class="user text-black">
                                <span v-if="!user">
                                  <router-link to="/signin">{{ $t("card.signIn") }}</router-link>
                                </span>
                                <span class="mdi mdi-account-outline text-base hover:text-red-600" v-else>
                                   <router-link to="/provider/profile">{{ user.name }}</router-link>
                                </span>
                            </div>
                            <div class="language-switch">
                                <SwitchLang/>
                            </div>
                        </div>
                        <nav class="px-2">
                            <button
                                :class="mobileNav"
                                v-if="user"
                                class="mdi mdi-account items-center flex gap-1"
                            >
                                <router-link to="/provider/profile">প্রোফাইল</router-link>
                            </button>

                            <button
                                :class="mobileNav"
                                v-if="user"
                                class="mdi mdi-car items-center flex gap-1"
                            >
                                <router-link to="/provider/car/index"> কার</router-link>
                            </button>

                            <button
                                :class="mobileNav"
                                v-if="user"
                                class="mdi mdi-cash-multiple items-center flex gap-1"
                            >
                                <router-link to="my-wallet"> পেমেন্ট</router-link>
                            </button>

                            <button
                                :class="mobileNav"
                                class="mdi mdi-help-box items-center flex gap-1"
                            >
                                <router-link to="/help"> হেল্প</router-link>
                            </button>
                            <div class="contact-us pl-2 mt-1">
                                <p class="pl-l">যোগাযোগ</p>
                                <ul>
                                    <li class="mt-5"><span class="mdi mdi-whatsapp"></span> <a
                                        href="https://wa.me/1871955556">WhatsApp</a></li>
                                    <li class="mt-5"><span class="mdi mdi-phone"></span><a href="tel:09638009008">
                                        09638009008</a></li>
                                </ul>
                            </div>
                        </nav>

                        <div
                            class="logout-rtb border-t border-black cursor-pointer px-4 text-red-500 absolute bg-white flex justify-center items-center w-full bottom-12 py-4"
                            v-if="user"
                            @click="logOut()"
                        >
                            <span class="mdi mdi-logout mr-2.5 text-xl text-black"></span>
                            <span class="" v-if="!isLoggingOut">লগআউট</span>
                            <span class="" v-else>লগআউট হচ্ছে</span>
                        </div>
                    </div>-->
                </div>
                <div class="flex-shrink-0 w-24" @click="sidebarOpen = false">
                    <!-- Force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </div>
        <!-- Off-canvas menu for mobile -->
        <!-- Menubar for desktop -->
        <div class="max-width mx-auto py-4 hidden md:block">
            <div class="grid grid-cols-5">
                <div class="flex col-span-2 justify-start items-center">
                    <router-link to="/">
                        <img class="w-40" src="/images/aside-square-black.png" alt="returntripbd"/>
                    </router-link>
                </div>

                <div class="col-span-3 flex justify-end items-center">
                    <SwitchLang class="px-3"/>

                    <router-link to="/" :class="navClass">
                        {{ $t("menu.home") }}
                    </router-link>

                    <!--<button
                        :class="navClass"
                        @click="setDialogComponent('TripCreateForm')"
                        v-if="user"
                      >
                        {{ $t("menu.createTrip") }}
                      </button>-->
                    <router-link
                        v-for="(menu, i) in menus"
                        :key="i"
                        :to="{ name: menu.link }"
                        :class="navClass"
                    >
                        {{ menu.name }}
                    </router-link>
                    <div
                        v-if="user"
                        @click="viewNotification()"
                        :class="navClass"
                        class="relative flex justify-center cursor-pointer"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-bell"
                            :class="
                notifications.length > 0
                  ? 'text-gray-700 bg-red-400 p-1 rounded-full'
                  : ''
              "
                        >
                            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        </svg>
                        <sup
                            v-if="notifications.length > 0"
                            class="count text-gray-700 text-sm mt-2 -ml-2"
                        >
                            {{ notifications.length > 9 ? "9+" : notifications.length }}
                        </sup>
                    </div>

                    <div
                        class="flex mr-3 items-center relative signin-signup-wrapper"
                        v-if="!user"
                    >
                        <button
                            @click="$router.push('/signup')"
                            class="text-primary ml-1 py-1 px-3 rounded header-signin-signup border-solid border-2"
                        >
                            {{ $t("card.signIn") }}
                        </button>
                        <div class="absolute text-sm text-gray-700 flex signup-section">
                            New User?
                            <span
                                class="signup colored-primary cursor-pointer"
                                @click="$router.push('/signup')"
                            >
                {{ $t("card.signUp") }}</span
                            >
                        </div>
                    </div>

                    <div class="relative" v-if="user">
                        <AccountDropdown
                            @onClickAccountDropdown="onClickAccountDropdown"
                            :userType="userType"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- Menubar for desktop -->
        <!-- Hamburger -->
        <div
            class="md:hidden mobile-header h-16 px-4 flex items-center"
            :class="user ? 'justify-between mobile-header-bg-black' : 'justify-start mobile-header-bg-white'"
        >
            <div v-if="!user">
                <div
                    v-if="$route.name !== 'BeforeSignin'"
                    @click="$router.go(-1)"
                    class="flex items-center justify-center cursor-pointer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM24 17H11.85L17.43 22.573L16 24L8.00001 16L16 8L17.43 9.393L11.85 15H24V17Z" fill="black"/>
                    </svg>
                </div>
            </div>
            <div
                v-if="user"
                @click.stop="toggleSidebar(true)"
                class="h-16 w-8 inline-flex items-center md:justify-center justify-start rounded-md text-gray-200 text-2xl hover:text-red-500 focus:outline-none transition ease-in-out duration-150"
            >
                <span class="mdi mdi-menu text-white"></span>
            </div>
            <router-link v-if="user" to="/" class="rtb-title flex items-center text-xl font-bold">
                রিটার্ন ট্রিপ
            </router-link>
            <div class="flex gap-2 items-center">
                <div
                    v-if="user"
                    @click="viewNotification()"
                    class="notification-bell relative flex justify-center cursor-pointer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M17.9383 22C17.9385 22.5046 17.7479 22.9906 17.4048 23.3605C17.0617 23.7305 16.5915 23.9572 16.0883 23.995L15.9383 24H13.9383C13.4337 24.0002 12.9478 23.8096 12.5778 23.4665C12.2078 23.1234 11.9812 22.6532 11.9433 22.15L11.9383 22H17.9383ZM14.9383 5C16.7533 4.99997 18.4973 5.70489 19.8026 6.96607C21.1078 8.22726 21.8721 9.94609 21.9343 11.76L21.9383 12V15.764L23.7603 19.408C23.8398 19.567 23.8796 19.7429 23.8763 19.9206C23.873 20.0984 23.8266 20.2727 23.7412 20.4286C23.6558 20.5845 23.5338 20.7174 23.3858 20.8158C23.2378 20.9143 23.0681 20.9754 22.8913 20.994L22.7763 21H7.10033C6.92249 21.0001 6.74729 20.957 6.58975 20.8745C6.4322 20.792 6.297 20.6725 6.19574 20.5264C6.09448 20.3802 6.03017 20.2116 6.00833 20.0351C5.98649 19.8586 6.00776 19.6795 6.07033 19.513L6.11633 19.408L7.93833 15.764V12C7.93833 10.1435 8.67582 8.36301 9.98858 7.05025C11.3013 5.7375 13.0818 5 14.9383 5Z" fill="white"/>
                    </svg>
                    <sup
                        v-if="notifications.length > 0"
                        class="count text-gray-700 text-sm mt-2 -ml-2"
                    >
                        {{ notifications.length > 9 ? "9+" : notifications.length }}
                    </sup>
                </div>
            </div>
        </div>
        <!-- Hamburger -->
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AccountDropdown from "@/components/molecule/auth/AccountDropdown";
import {menus} from "@/data/menu";
import SwitchLang from "@/components/atom/SwitchLang";
import Form from "@/services/form";
import axios from "axios";
import {clearDeviceInterests} from "@/services/clearDeviceInterests";
import {directive} from "vue3-click-away";

export default {
    name: "Header",
    components: {
        AccountDropdown,
        SwitchLang,
    },

    data() {
        return {
            isLoggingOut: false,
            notifications: [],
            form: new Form(),
            navClass: [
                "px-4",
                "font-semibold",
                "hover:text-primary",
                "text-gray-700",
                "text-sm",
            ],
            mobileNav:
                "group flex items-center px-2 py-2 mb-2 text-base leading-6 font-medium rounded-md text-black focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150",
            menus,
            signin: true,
        };
    },

    directives: {
        ClickAway: directive
    },

    computed: {
        ...mapGetters({
            user: "user/getUser",
            sidebarOpen: "getSidebarStatus",
        }),

        userType() {
            return this.user ? this.user?.type : false;
        },
    },

    methods: {
        ...mapMutations({
            setUserInfo: "user/setLoggedInUser",
            setDialogComponent: "setDialogComponent",
            setSidebarStatus: "setSidebarStatus",
            setDialog: "setDialog",
        }),
        navigate(link) {
            this.setSidebarStatus(false);
            this.$router.push(link);
        },
        handleDialog(name) {
            this.setSidebarStatus(false);
            this.setDialogComponent(name);
        },
        onClickAccountDropdown() {
            this.setSidebarStatus(false);
        },

        toggleSidebar(value) {
            this.setSidebarStatus(value);
        },
        async logOut() {
            localStorage.removeItem("trip_auth_user");
            localStorage.removeItem("token");
            localStorage.removeItem("imp_token");
            clearDeviceInterests();
            this.isLoggingOut = true;
            try {
                await this.form.post("auth/logout");
            } catch (err) {
                if (err.response) {
                }
            } finally {
                this.setUserInfo(null);
                this.$router.push("/signup");
            }
        },
        viewNotification() {
            this.$router.push("/notifications");
        },
        getNotifications(url) {
            axios.get(url).then((res) => {
                this.notifications = res.data.data.data;
            });
        },
        onClickAway() {
            if (this.sidebarOpen) {
                this.toggleSidebar(!this.sidebarOpen)
            }
        }
    }, //end of methods
    mounted() {
        let url = "notifications/unseen";
        if (this.user) {
            this.getNotifications(url);
        }
    },
    props: {
        logo: {
            default: "/images/aside-square-black.png",
        },
    },

    created() {
        this.setUserInfo();
    },

    watch: {
        $route: function () {
            this.setSidebarStatus(false);
            this.setDialog(false);
        },
    },
};
</script>

<style scoped lang="scss">
.rtb-header {
    background: #fff;
    //@apply shadow-lg md:hidden block;
    font-family: 'Noto Sans Bengali',serif;
}

.signin-signup-wrapper {
    min-width: 130px;
    display: grid;
    justify-content: flex-end;
}

.header-signin-signup {
    border-color: #ff4365;
    min-width: 120px;
}

.colored-primary {
    color: #ff4365;
}

.signup {
    font-weight: bold;
}

.signup-section {
    font-size: 12px !important;
    gap: 5px;
    bottom: -20px !important;
}

.vertical-menu-dots {
    max-height: 24px;
    max-width: 24px;
}


.vsc-popup-button--default {
    background-color: #69c157 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.vsc-popup {
    bottom: 50px !important;
}

.vsc-popup-button {
    height: 40px !important;
    width: 40px !important;
}

.vsc-popup-button:focus,
.vsc-popup-button:hover {
    box-shadow: none !important;
}

.max-width {
    max-width: 1260px;
}

.contact-us p {
    border-bottom: 3px solid #000;
    max-width: fit-content;
    padding-right: 10px;
    padding-bottom: 2px;
}

.mobile-header {
    font-family: 'Noto Sans Bengali',serif;
    .rtb-title {
        color: #ffffff;
    }
}
.mobile-header-bg-white {
    background: #F8FAFC;
}
.mobile-header-bg-black {
    background: #000000;
}
.mid-line {
    height: 2px;
    width: 100%;
    margin: 0 10px;
    border-radius: 10px;
    background: #BBC6D0;
}

.user-name-email {
    color: #3A3A3A;

    .email {
        font-size: 12px;
        font-weight: 400;
    }
}

.menu-item {
    color: #3A3A3A;
}

.logout-rtb {
    border-radius: 10px;
    background: #000000;
}

@media (min-width: 640px) {
    .notification-bell svg {
        height: 30px;
        width: 30px;

        path {
            fill: gray;
        }
    }
}
</style>
