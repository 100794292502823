<template>
    <div class="provider-car-box">
		<div v-if="car?.car_status !== 'active'" class="verification-message-box bg-gradient-to-r from-blue-200 to-cyan-200 p-3 md:text-base text-xs"><p> {{$t("card.carMessage")}}</p>
		</div>
	<div class="bg-whiteBlue rounded-md shadow-xl p-2 grid grid-cols-12 relative">
		<div
			class="text-bluishBlack flex gap-x-1 text-sm tracking-widest font-medium absolute right-1 top-1"
		>
			<CarStatus :status="car?.car_status" />
            <button
                v-if="car?.car_status === 'verification_pending'"
                class="bg-red-400 text-white rounded-sm px-2 py-1"
                @click="goToEditCarForm(car?.car_id)">
                <span class="mdi mdi-open-in-new"></span>
                {{$t("update")}}
            </button>

		</div>
		<div class="w-full col-span-3">
			<Carousel :slides="carImages" :responsive="responsive">
				<template v-slot="{ slide }">
					<img
						:src="slide"
						class="max-h-48 md:h-auto h-24 mx-auto rounded"
						alt=""
					/>
				</template>
			</Carousel>
		</div>
		<div class="col-span-9 max-w-2xl">
			<div class="grid grid-cols-12 md:pl-8 pl-2 md:pt-10 pt-8">
				<div class="md:col-span-6 col-span-12">
					<div
						class="font-semibold text-gray-400 text-xs md:text-sm hidden md:block"
					>
						{{ $t("triCreateForm.carModel") }}
					</div>
					<div
						class="car-info-section grid gap-y-2 text-bluishBlack text-sm tracking-widest font-medium my-2"
					>
						<span
							>{{ car?.brand?.name.toUpperCase() }} {{ car?.model }}
							{{ car?.manufacture_year }} ({{car?.number_of_seat }} Seats)</span
						>
						<span>{{ car?.car_type }} | {{ car?.color }} | {{ car?.is_air_conditioned ? "AC" : "Non AC" }}</span>
					</div>
				</div>

				<div class="md:col-span-6 col-span-12">
					<div
						class="font-semibold text-gray-400 text-xs md:text-sm hidden md:block"
					>
						Car Number
					</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium md:my-2 my-0"
					>
						{{ car.registration_number }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapMutations } from "vuex";
import Carousel from "@/components/atom/Carousel";
import { responsive } from "@/config/carousel";
import CarStatus from "@/components/atom/Status";
export default {
	name: "CarInformationCard",
	components: {
		Carousel,
		CarStatus,
	},

	data: () => ({
		responsive,
	}),

	props: {
		car: {
			type: Object,
		},
	},

	computed: {
		carImages() {
			let urls = this.car?.images?.map((i) => i.file_path)
			return urls.slice().sort(this.customSort)
		},
	},

	methods: {
		...mapMutations({
			setDialogComponent: "setDialogComponent",
			setCarId: "setCarId",
			setCar: "setCar",
		}),

		handleCarModal() {
			this.setCar(this.car);
			this.setDialogComponent("CarEditForm");
		},

        goToEditCarForm(carId) {
            this.$router.push({ name: "CarEdit", params: { id: carId } });
        },

		customSort(a, b) {
			const patternOrder = {
			"0-front": 0,
			"1-back": 1,
			"2-seat": 2
			};
		
			const matchA = a.match(/(\d+-\w+)\.(\w+)$/);
			const matchB = b.match(/(\d+-\w+)\.(\w+)$/);
		
			const keyA = matchA ? matchA[1] : "";
			const keyB = matchB ? matchB[1] : "";
		
			return patternOrder[keyA] - patternOrder[keyB];
		}
	},
};
</script>

<style></style>
