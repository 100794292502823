<template>
    <div class="mt-24">
        <Feed  />
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TripSearchSection from "@/components/molecule/home/TripSearch";
import FeedSection from "@/components/molecule/feed/PostTripSection";
import userMixin from "@/mixins/userMixin";
import Feed from "@/views/Feed.vue";
import {getProfile} from "@/services/module/profile";
import NidDobUpdate from "@/components/molecule/profile/NidDobUpdate.vue";
export default {
	name: "Home",
	mixins: [userMixin],
	components: {
        Feed,
		TripSearchSection,
		FeedSection,
        NidDobUpdate
	},

	data(){
		return {
		}
	},

	computed: {
		...mapGetters({
			user: "user/getUser",
		}),

	},

	methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
        }),
        // get notification permission from user
        async getNotificationPermission() {
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else {
                if (Notification.permission === "granted") {
                    console.log("Permission to receive notifications has been granted");
                } else {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === "granted") {
                            console.log("Permission to receive notifications has been granted");
                        }
                    });
                }
                console.log("This browser supports desktop notification")
            }
        },


	},

	created() {
        if (!this.user) {
            this.$router.push({ name: "Signup" });
        }
        this.getNotificationPermission();
        if (this.user) {
            let today = new Date();
            let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            let localDate = localStorage.getItem('date');
            if (localDate !== date) {
                localStorage.setItem('date', date);
                this.setDialogComponent('OfferModal')
            }
        }
    },
};
</script>
