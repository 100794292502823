<template>
	<div class="flex" v-if="shown">
        <p :class="properClass" class="text-center text-xs px-3 py-1 rounded-sm" v-if="statusMap[status]">{{ statusMap[status] }}</p>
        <p :class="properClass" class="text-center text-xs px-3 py-1 rounded-sm" v-else>{{ status }}</p>
	</div>
</template>

<script>
import { computed } from "vue";
import { toRef } from "vue";
export default {
	name: "TripStatus",
	props: {
		status: {
			required: true,
		},
	},
	data() {
		return {
			shown: true,
            statusMap: {
                active: this.$t("trip_status.active"),
                extended: this.$t("trip_status.extended"),
                hold: this.$t("trip_status.hold"),
                completed: this.$t("trip_status.completed"),
                canceled: this.$t("trip_status.canceled"),
                draft: this.$t("trip_status.draft"),
                verification_pending: this.$t("trip_status.verification_pending"),
            }
		};
	},
	setup(props) {
		//active, inactive, disable & verification pending
		const status = toRef(props, "status");
		const properClass = computed(() => {
			if (
				status.value === "confirmed" ||
				status.value === "active" ||
                status.value === "completed" ||
                status.value === "started" ||
				status.value === "hold"
			)
				return "status-confirm";
			else if (
                status.value === "proposed" ||
                status.value === "extended"
            ) return "status-proposed";
			else if (status.value === "requested") return "status-requested";
			else if (
				status.value === "closed" ||
				status.value === "disable" ||
				status.value === "expired" ||
				status.value === "inactive" ||
				status.value === "canceled" ||
				status.value === "verification pending" ||
				status.value === "verification_pending"
			)
				return "status-closed";
			else return "";
		});
		return {
			status,
			properClass,
		};
	},

	mounted() {
		if ((this.$route.name == "Home") || (this.$route.name == 'Feed')) {
			this.shown = false;
		}
	},
};
</script>

<style scoped>
.status-confirm  {
	@apply bg-green-200 text-gray-500;
}
.status-proposed {
	@apply bg-blue-400 text-gray-100;
}
.status-requested {
	@apply bg-blue-400 text-gray-100;
}
.status-closed {
	@apply bg-red-400 text-gray-100;
}
</style>
