<template>
	<div class="flex items-center ml-4 mt-4 md:ml-0 md:mt-0">
		<button
			@click="showDropdown = !showDropdown"
			class="
				text-gray-700 text-2xl
				outline-none
				focus:outline-none
				flex
				items-center
			"
		>
			<RoundImage
				heightWeight="w-6 h-6 md:w-6 md:h-6"
				imageStyle="shadow-md rounded-full border-2 border-gray-100"
				:image="user.profile_photo_url"
			/>
			<p class="text-xs px-1">{{ user.name.split(" ")[0] }}</p>
			<svg
				:class="[showDropdown ? 'opened' : 'closed']"
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="feather feather-chevron-down"
			>
				<polyline points="6 9 12 15 18 9"></polyline>
			</svg>
		</button>

		<div
			class="
				md:hidden
				origin-top-right
				top-14
				left-3
				md:top-10 md:left-auto
				absolute
				z-50
				md:right-0
				mt-2
				w-56
				rounded-md
				shadow-lg
				bg-white
				ring-1 ring-black ring-opacity-5
			"
		>
			<div
				class="py-1"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<p
					class="cursor-pointer block capitalize px-4 py-2 hover:bg-gray-100"
					@click="
						logOut();
						$emit('onClickAccountDropdown');
					"
				>
					<span
						class="text-sm text-gray-700 hover:text-gray-900"
						v-if="!isLoggingOut"
						>Log Out</span
					>
					<span class="text-sm text-gray-700 hover:text-gray-900" v-else
						>Logging Out</span
					>
				</p>
			</div>
		</div>

		<div
			v-if="showDropdown"
			class="
				hidden
				md:block
				origin-top-right
				top-14
				left-3
				md:top-10 md:left-auto
				absolute
				z-50
				md:right-0
				mt-2
				w-56
				rounded-md
				shadow-lg
				bg-white
				ring-1 ring-black ring-opacity-5
			"
		>
			<div
				class="py-1"
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu"
			>
				<template v-for="(item, i) in links">
					<router-link
					    :to="{name: item.link}"
						:key="i"
						@click="
							nevigate();
							$emit('onClickAccountDropdown');
						"
						v-if="userType == item.type"
						class="cursor-pointer block capitalize px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
					>
						{{ item.title }}
					</router-link>
				</template>

				<p
					class="cursor-pointer block capitalize px-4 py-2 hover:bg-gray-100"
					@click="
						logOut();
						$emit('onClickAccountDropdown');
					"
				>
					<span
						class="text-sm text-gray-700 hover:text-gray-900"
						v-if="!isLoggingOut"
						>লগআউট</span
					>
					<span class="text-sm text-gray-700 hover:text-gray-900" v-else
						>লগআউট হচ্ছে</span
					>
				</p>
			</div>
		</div>
	</div>
	<div
		v-if="showDropdown"
		@click="showDropdown = !showDropdown"
		class="fixed inset-0 bg-black z-40 opacity-0"
	></div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { links } from "@/data/loggedinuserlinks";
import RoundImage from "@/components/atom/RoundImage";
import Form from "@/services/form";
import {clearDeviceInterests} from "@/services/clearDeviceInterests";
export default {
	name: "AccountDropdown",
	emits: ["onClickAccountDropdown"],
	components: {
		RoundImage,
	},

	data() {
		return {
			isLoggingOut: false,
			form: new Form(),
			showDropdown: false,
			links,
		};
	},

	props: {
		userType: { required: false },
	},

	computed: {
		...mapGetters({
			user: "user/getUser",
		}),
	},

	methods: {
		...mapMutations({
			setUserInfo: "user/setLoggedInUser",
		}),

		nevigate() {
			this.showDropdown = false;
		},

		async logOut() {
			localStorage.removeItem("trip_auth_user");
			localStorage.removeItem("token");
			localStorage.removeItem("imp_token");
            clearDeviceInterests();
			this.isLoggingOut = true;
			try {
				await this.form.post("auth/logout");
			} catch (err) {
				if (err.response) {
				}
			} finally {
				this.setUserInfo(null);
				this.$router.push("/signup");
			}
		},
	}, //end of methods

	created() {
		this.setUserInfo();
	},
};
</script>

<style>
svg.opened {
	transform: rotate(180deg);
	transition: all 300ms ease-in-out;
}
svg.closed {
	transition: all 300ms ease-in-out;
}
</style>
