<template>
	<section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-1 py-4 bg-white shadow  md:hidden">
    <div id="tabs" class="flex justify-between">
     <button v-if="userType" @click="nevigate('Home')" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pb-1">
        <i :class="`mdi mdi-home-variant text-2xl ${routeName == 'Home' || routeName == 'Feed' ? 'text-red-400' :'text-gray-400'} lieding-none`"></i>
        <span :class="`py-1 capitalize tab tab-home ${routeName == 'Home' || routeName == 'Feed' ? 'text-red-500' :'text-gray-500'} block text-xs leading-none -mt-2`">হোম</span>
     </button>

    <!--<button v-if="userType" @click="nevigate('TripCreate')" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pb-1">
        <i :class="`mdi mdi-plus-circle-outline text-2xl ${routeName == 'TripCreate' ? 'text-red-400' :'text-gray-400'} lieding-none`"></i>
        <span :class="`tab tab-home ${routeName == 'TripCreate' ? 'text-red-500' :'text-gray-500'} block text-xs leading-none -mt-2`">Trip Request</span>
     </button>-->

     <template v-for="(link, i) in links" :key="i">
      <BottomNavItem 
      	v-if="link.type == userType"
      	:item="link"
      />
     </template>
    </div>
  </section>
</template>

<script>
	import { mapGetters, mapMutations } from "vuex";
	import BottomNavItem from './BottomNavItem';
	import {linksWithIcon} from '@/data/loggedinuserlinks';

	export default {
		name: 'BottomNav',
		data: () => ({
			links: linksWithIcon
		}),
		components: {
			BottomNavItem
		},

		computed: {
			...mapGetters({
				user: "user/getUser",
		      	sidebarOpen: "getSidebarStatus"
		    }),

		    userType() {
		      return this.user ? this.user?.type : false;
		    },

		    routeName() {
				return this.$route.name
			}
		},

		methods: {
			 ...mapMutations({
		      setSidebarStatus: 'setSidebarStatus',
		      setDialogComponent: "setDialogComponent",
		    }),

			 nevigate(link) {
			 	this.$router.push({name: link})
			 }
		}
	}
</script>