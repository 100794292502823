<template>
	<div class="flex flex-col items-center">
		<h4 class="py-5 text-gray-500">{{title}}</h4>
		<img
			:class="imageStyle"
			:src="image" 
			alt="tripbd not found"
		>
	</div>
</template>

<script>
	export default{
		name: 'NoData',
		props: {
			title: {
				type: String,
				default: 'No record found'
			},
			image: {
				type: String,
				default: '/images/no_data_availible.png'
			},

			imageStyle: {
				type: String,
				default: 'w-64'
			}
		}
	}
</script>