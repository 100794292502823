const engToBd = {
    methods: {
        engToBdNum(str) {
            let banglaNumber = {
              0: "০",
              1: "১",
              2: "২",
              3: "৩",
              4: "৪",
              5: "৫",
              6: "৬",
              7: "৭",
              8: "৮",
              9: "৯"
            };
            for (let x in banglaNumber) {
              str = str.replace(new RegExp(x, "g"), banglaNumber[x]);
            }
            return str;
          },

          timeConvertToText(str) {
            let time;
            switch (str) {
                case '12:00 AM':
                    time = 'রাত ১২:০০ টা'
                    break;
                case '12:30 AM':
                    time = 'রাত ১২:৩০ টা'
                    break;
                case '01:00 AM':
                    time = "রাত ০১:০০ টা"
                    break;
                case '01:30 AM':
                    time = "রাত ০১:৩০ টা"
                    break;
                case '02:00 AM':
                    time = "রাত ০২:০০ টা"
                    break;
                case '02:30 AM':
                    time = "রাত ০২:৩০ টা"
                    break;
                case '03:00 AM':
                    time = "রাত ০৩:০০ টা"
                    break;
                case '03:30 AM':
                    time = "রাত ০৩:৩০ টা"
                    break;
                case '04:00 AM':
                    time = "রাত ০৪:০০ টা"
                    break;
                case '04:30 AM':
                    time = "রাত ০৪:৩০ টা"
                    break;
                case '05:00 AM':
                    time = "সকাল ০৫:০০ টা"
                    break;
                case '05:30 AM':
                    time = "সকাল ০৫:৩০ টা"
                    break;
                case '06:00 AM':
                    time = "সকাল ০৬:০০ টা"
                    break;
                case '06:30 AM':
                    time = "সকাল ০৬:৩০ টা"
                    break;
                case '07:00 AM':
                    time = 'সকাল ০৭:০০ টা'
                    break;
                case '07:30 AM':
                    time = 'সকাল ০৭:৩০ টা'
                    break;
                case '08:00 AM':
                    time = 'সকাল ০৮:০০ টা'
                    break;
                case '08:30 AM':
                    time = 'সকাল ০৮:৩০ টা'
                    break;
                case '09:00 AM':
                    time = 'সকাল ০৯:০০ টা'
                    break;
                case '09:30 AM':
                    time = 'সকাল ০৯:৩০ টা'
                    break;
                case '10:00 AM':
                    time = 'সকাল ১০:০০ টা'
                    break;
                case '10:30 AM':
                    time = 'সকাল ১০:৩০ টা'
                    break;
                case '11:00 AM':
                    time = 'দুপুর ১১:০০ টা'
                    break;
                case '11:30 AM':
                    time = 'দুপুর ১১:৩০ টা'
                    break;
                case '12:00 PM':
                    time = 'দুপুর ১২:০০ টা'
                    break;
                case '12:30 PM':
                    time = 'দুপুর ১২:৩০ টা'
                    break;
                case '01:00 PM':
                    time = 'দুপুর ০১:০০ টা'
                    break;
                case '01:30 PM':
                    time = 'দুপুর ০১:৩০ টা'
                    break;
                case '02:00 PM':
                    time = 'দুপুর ০২:০০ টা'
                    break;
                case '02:30 PM':
                    time = 'দুপুর ০২:৩০ টা'
                    break;
                case '03:00 PM':
                    time = 'দুপুর ০৩:০০ টা'
                    break;
                case '03:30 PM':
                    time = 'দুপুর ০৩:৩০ টা'
                    break;
                case '04:00 PM':
                    time = 'বিকাল ০৪:০০ টা'
                    break;
                case '04:30 PM':
                    time = 'বিকাল ০৪:৩০ টা'
                    break;
                case '05:00 PM':
                    time = 'বিকাল ০৫:০০ টা'
                    break;
                case '05:30 PM':
                    time = 'বিকাল ০৫:৩০ টা'
                    break;
                case '06:00 PM':
                    time = 'বিকাল ০৬:০০ টা'
                    break;
                case '06:30 PM':
                    time = 'বিকাল ০৬:৩০ টা'
                    break;
                case '07:00 PM':
                    time = 'সন্ধ্যা ০৭:০০ টা'
                    break;
                case '07:30 PM':
                    time = 'সন্ধ্যা ০৭:৩০ টা'
                    break;
                case '08:00 PM':
                    time = 'রাত ০৮:০০ টা'
                    break;
                case '08:30 PM':
                    time = 'রাত ০৮:৩০ টা'
                    break;
                case '09:00 PM':
                    time = 'রাত ০৯:০০ টা'
                    break;
                case '09:30 PM':
                    time = 'রাত ০৯:৩০ টা'
                    break;
                case '10:00 PM':
                    time = 'রাত ১০:০০ টা'
                    break;
                case '10:30 PM':
                    time = 'রাত ১০:৩০ টা'
                    break;
                case '11:00 PM':
                    time = 'রাত ১১:০০ টা'
                    break;
                case '11:30 PM':
                    time = 'রাত ১১:৩০ টা'
                    break;
                default:
                    break;
            }
        
            return time;
          }
    }
}


export default engToBd;