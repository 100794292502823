<template>
    <div class="h-full mt-24">
        <div v-if="due" class="feed-page-trip-search max-w-7xl mx-auto p-2 my-1">
            <div class="flex justify-center">
                <button @click="gotToWallet" class="bg-primaryDeep text-sm text-white py-2 px-4">
                    {{ $t("wallet.your_dues") }} :
                    {{ $i18n.locale === "bn" ? Math.abs(balance).toLocaleString("bn-BD") : Math.abs(balance) }} ৳
                    <span class="mdi mdi-arrow-right-bold text-white"></span>
                </button>
            </div>
        </div>
        <div>
            <div>
                <PostTripSection :trips="trips"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import TripDropdownSection from "@/components/molecule/home/TripDropdownSection";
import PostTripSection from "@/components/molecule/feed/PostTripSection";
import BookingManager from "@/services/module/booking";
import {mapGetters, mapMutations} from "vuex";
import userMixin from "@/mixins/userMixin";
import TripCreateForm from "@/components/molecule/company/TripCreateForm.vue";

export default {
    name: "Feed",
    mixins: [userMixin],
    components: {
        PostTripSection,
        TripDropdownSection,
        TripCreateForm,
    },

    data() {
        return {
            bookingManager: new BookingManager(),
            trips: '',
            pick_str: "",
            dest_str: "",
            filter: {
                bookingStatus: "",
                tripStatus: "active"
            },
            balance: 0,
            due: false,
            feedShow: true,
        };
    },

    computed: {
        ...mapGetters({
            user: "user/getUser",
            bookings: "bookings/getBookingList",
        }),
        apiEnd() {
            return this.userType == "provider"
                ? "booking-provider/index"
                : "booking-user/index";
        },


    },

    methods: {
        ...mapMutations({
            setBookings: "bookings/setBookings",
        }),

        goToTripRequest() {
            this.$router.push({name: "TripCreate"});
        },
        gotToWallet() {
            this.$router.push({name: "Wallet"});
        },

        getTrips() {
            if (this.$route.query.pdv) {
                this.pick_str += this.$route.query.pdv;
            }
            if (this.$route.query.pdst) {
                this.pick_str = this.pick_str.concat(",", this.$route.query.pdst);
            }

            if (this.$route.query.ddv) {
                this.dest_str += this.$route.query.ddv;
            }
            if (this.$route.query.ddst) {
                this.dest_str = this.dest_str.concat(",", this.$route.query.ddst);
            }

            axios.get(`front-trip/index?searchPickLocation=${this.pick_str}&searchDestLocation=${this.dest_str}`)
                .then((res) => {
                    this.trips = res?.data?.data?.data;
                });
        },

        async fetchBookingLists() {
            try {
                let res = await this.bookingManager.bookings(this.apiEnd, this.filter);
                console.log(res)
                if (res.success) {
                    let trip = res.data.data;
                    this.setBookings(trip);
                    localStorage.setItem('bookingProvider', JSON.stringify(trip))
                }
            } catch (err) {
                if (err.response?.status === 500) {
                    console.log(err.response?.statusText)
                    return;
                }
                console.log("Something went wrong")
            }
        },
        getWalletDetails() {
            axios.get(`wallet/provider/details`).then((res) => {
                this.wallet_details = res.data.data;
                this.balance = this.wallet_details?.cash_payable_balance
                this.due = this.balance > 0
            })
                .catch((err) => {
                    this.err_message = err.response.data.message
                });
        },
    },

    mounted() {
        this.getTrips();
        this.fetchBookingLists();
        if (this.userType === "provider") {
            this.getWalletDetails();
        }
    },
};
</script>

<style>
.feed-page-trip-search .pickup-and-destination-search {
    display: flex;
}

.feed-page-trip-search .pickup-and-destination-search .pickup-search,
.feed-page-trip-search .pickup-and-destination-search .destination-search {
    width: 100%;
}
</style>
